import { Component, OnInit } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items
export const ROUTESS: RouteInfo[] = [{
    path: '/supervisor',
    title: 'Supervisor-Dashboard',
    type: 'link',
    icontype: 'dashboard'
},
{
    path: '/supervisor-historicalmetrics',
    title: 'Historical Metrics',
    type: 'sub',
    icontype: 'access_time',
    collapse: 'historicalmetrics',
    children: [
        { path: 'callanalytics', title: 'Call Analytics', ab: 'CA' },
        { path: 'agentanalytics', title: 'Agent Analytics', ab: 'AA' },
        { path: 'queueanalytics', title: 'Queue Analytics', ab: 'QA' },
    ]
},
{
    path: '/supervisor-advancedanalytics',
    title: 'Advanced Analytics',
    type: 'sub',
    icontype: 'timeline',
    collapse: 'advancedanalytics',
    children: [
        { path: 'trendanalytics', title: 'Trend Analytics', ab: 'TA' },
        { path: 'sentimentanalytics', title: 'Sentiment Analytics', ab: 'SA' },
        { path: 'linguisticanalytics', title: 'Linguistic Analytics', ab: 'LA' },
        { path: 'covidanalytics', title: 'COVID Analytics', ab: 'CA' },
       // { path: 'test', title: 'Test', ab: 'S'}
    ]
},

{
    path: '/supervisor-reports',
    title: 'Reports',
    type: 'sub',
    icontype: 'bar_chart',
    collapse: 'admin',
    children: [
        { path: 'agentperformance', title: 'Agent Performance', ab: 'CA' },
        { path: 'contactsearch', title: 'Contact Search', ab: 'CA' }
    ]
},
// {
//     path: '/stellar_assistant',
//     title: 'Engage Assistant',
//     type: 'link',
//     icontype: 'assistant'
// },
// {
//     path: '/supervisor-configure',
//     title: 'Configure',
//     type: 'link',
//     icontype: 'settings'
// }
{
    path: '/search_page',
    title: '',
    type: '',
    icontype: ''
},
{
    path: '/compliance',
    title: '',
    type: '',
    icontype: ''
}
];
@Component({
    selector: 'app-supervisorsidebar-cmp',
    templateUrl: 'supervisorsidebar.component.html',
})

export class SupervisorSidebarComponent implements OnInit {
    public menuItems: any[];
    ps: any;
    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    ngOnInit() {
        this.menuItems = ROUTESS.filter(menuItem => menuItem);
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }
    }
    updatePS(): void {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
}
